<template>
  <div>
    <div class="d-flex">
      <label class="mr-4 mt-1">
        {{ $t('admin.duplicate.name') }}
      </label>
      <d-text-field
        v-model.trim="category.name"
        :rules="validation.name"
        :identifier="category['@id']"
        class-name="bg-white"
        size="sm"
        error-text="validation.required"
      />
      <i @click="onDuplicate('name')" class="pointer ml-4 mt-1 fa-15x" :class="icon"></i>
    </div>
    <span
      v-if="message('name', category['@id'])"
      class="invalid-feedback d-block mt-0"
    >
         {{ message('name', category['@id']) }}
    </span>

    <div class="mt-2">
      <label>
        {{ $t('admin.duplicate.description-cat') }}
      </label>

      <i @click="onDuplicate('description')" class="pointer ml-4 fa-15x" :class="icon"></i>
      <b-textarea
        v-model="category.description"
        type="text"
      />
    </div>

    <div v-if="hasBorder" class="border-bottom-black mt-3 mb-4"/>
  </div>
</template>

<script>
export default {
  data: () => ({
    validation: require('@validation/entities/ShopCategory.json')
  }),
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: 'icofont icofont-download-alt'
    },
    category: {
      type: Object,
      default: () => {
      }
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    message(field, target) {
      for (const message of this.messages) {
        if (message.field === field && target === message.iri) {
          return message.content;
        }
      }

      return false;
    },
    onDuplicate(field) {
      this.$store.commit('duplicate/updateCategories', {
        field: field,
        name: this.category.name,
        description: this.category.description
      });
    }
  },
}
</script>

<style scoped lang="scss">
.fa-15x {
  font-size: 1.3em;
}

.border-bottom-black {
  border: 1.6px solid #858484;
  border-radius: 10px;
  margin: auto;
}
</style>
