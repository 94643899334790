<template>
  <b-modal
    :id="'modal-delete-'+ modalId"
    hide-header
    hide-footer
    hide-header-close
    size="lg"
    header-class="my-second-class"
    :body-bg-variant="'gray-lighten'"
  >
    <b-row>
      <b-col align="center" class="modal-title-class">
        {{ $t(title) }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3">
    </div>
    <b-row>
      <b-col align="center" class="">
        {{ description }}
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">

      </b-col>
      <b-col align="middle" cols="12">
        <b-progress class="mt-2" :animated="isBusy" show-progress :value="100 / count.totalCount * (count.success + count.failed) + ' %'" :variant="isBusy ? 'warning' : 'success'"></b-progress>

        <span class="text-danger d-flex justify-content-center row mt-1"> Échoué : {{ count.failed }}</span>
        <span class="text-success d-flex justify-content-center row mt-1"> Success : {{ count.success }}</span>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="pr-0" align="middle">
        <d-button
          v-if="!((count.success + count.failed) === count.totalCount && count.totalCount !==0)"
          :text="'general.actions.cancel'"
          :class="'d-btn-sm d-btn btn d-btn-danger font-text-title mr-2'"
          @on:button-click="$emit('on:cancel')"
        />
        <d-button
          :class="isBusy ? 'disabled' : ''"
          :icon="isBusy ? 'fa fa-spinner fa-spin' : ''"
          :text="textButton"
          class="d-btn-sm d-btn btn d-btn-primary-new font-text-title"
          @on:button-click="onConfirm"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

export default {
  props: {
    text: {
      type: String,
      default: 'general.actions.delete-modal-text'
    },
    modalId: {
      type: String,
      default: 'default'
    },
    isBusy: {
      type:Boolean,
      default: false,
    },
    count: {
      type: Object,
      default: {success: 0, failed: 0, totalCount: 0}
    },
    title: {
      type: String,
      default: 'general.actions.delete-modal-title'
    },
    display: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    textButton() {
      return (this.count.success + this.count.failed < this.count.totalCount) ? 'general.actions.validate' : 'admin.duplicate.completed';
    },
    description() {
      if (this.count.success === 0 && this.count.failed === 0) {
        return this.$t(this.text);
      } else if (this.count.success +this.count.failed < this.count.totalCount) {
        return this.$t('admin.duplicate.progress');
      } else {
        return this.$t('admin.duplicate.ended');
      }
    }
  },
  watch: {
    display: function (changes) {
      this.$bvModal.show('modal-delete-' + this.modalId);
    },
    hide: function (changes) {
      this.$bvModal.hide('modal-delete-' + this.modalId);
    }
  },
  methods: {
    onConfirm() {
      if (this.textButton === 'general.actions.validate') {
        this.$emit('on:confirm');
      } else {
        this.$bvModal.hide('modal-delete-' + this.modalId);
      }
    },
  },
  beforeDestroy() {
    try {
    } catch (e) {
    }
  },
}
</script>
<style scoped>
.modal-title-class {
  text-align: center;
  font: normal normal 500 30px Avenir;
  letter-spacing: 0px;
  color: #3C3D43;
  opacity: 1;
}

/deep/ .modal {
  top: 100px
}

/deep/ .modal-content {
  border-radius: 8px;
  width: calc(100% - 150px);
  left: 100px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}

</style>
