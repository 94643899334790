<template>
  <b-row>
    <b-col cols="6">
      <div class="text-underline title-h2 mb-3 mt-2">{{ $t('admin.duplicate.title') }}</div>
      <div class="simple-label-avenir mb-4">{{ $t('admin.duplicate.description') }}</div>

    </b-col>
    <b-col
      class="d-flex justify-content-end align-items-center"
      cols="6"
    >
      <d-button
        text="admin.duplicate.cancel-edit"
        class="d-btn-sm font-text-title btn d-btn-danger "
        @on:button-click="$emit('on:cancel-edit')"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {}
</script>
<style scoped lang="scss">
@import "@lazy/_b-card.scss";
</style>
